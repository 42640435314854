<template>
    <ProductList v-model:productListVisible="productListVisible" @onSelect="onSelectProduct" />
    <a-modal
        v-model:visible="visible"
        :title="`${upDateRow ? '编辑' : '新增'}规则`"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="700px"
    >
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="规则编码"
                name="code"
                :rules="[
                    { validator: checkAllSpace },
                    { required: true, message: '请输入规则编码' },
                ]"
            >
                <a-input
                    v-model:value="formData.code"
                    :maxlength="100"
                    showCount
                    :disabled="Boolean(upDateRow)"
                />
            </a-form-item>

            <a-form-item
                label="规则名称"
                name="name"
                :rules="[
                    { required: true, message: '请输入规则名称' },
                    { validator: checkAllSpace },
                ]"
            >
                <a-input v-model:value="formData.name" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="存货档案"
                name="item"
                :rules="[
                    { required: true, message: '请选择存货档案' },
                    { validator: checkAllSpace },
                ]"
            >
                <a-form-item>
                    <SelectInput
                        :title="formData.item"
                        :onClick="() => (productListVisible = true)"
                        :showDeleteIcon="false"
                    ></SelectInput>
                </a-form-item>
            </a-form-item>

            <a-form-item
                label="供应商名称"
                name="supplierName"
                :rules="[
                    { required: true, message: '请输入供应商名称' },
                    { validator: checkAllSpace },
                ]"
            >
                <a-input v-model:value="formData.supplierName" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="物料条码位数"
                name="barcodeLength"
                :rules="[
                    { required: true, message: '请输入物料条码位数' },
                    { validator: checkAllSpace },
                ]"
            >
                <a-input-number
                    v-model:value="formData.barcodeLength"
                    :min="1"
                    :step="1"
                    style="width: 100%"
                    :precision="0"
                />
            </a-form-item>

            <a-form-item name="barcodeBatchNo" :rules="[{ validator: checkContainerNo }]">
                <template v-slot:label>批次号</template>
                <a-form-item>
                    <a-input-number
                        v-model:value="formData.barcodeBatchNoFrom"
                        :min="0"
                        :step="1"
                        :precision="0"
                        style="width: 227px"
                        @change="value => (formData.barcodeBatchNo[0] = value)"
                    />
                    <span style="margin: 0 10px">至</span>
                    <a-input-number
                        v-model:value="formData.barcodeBatchNoTo"
                        :min="0"
                        :step="1"
                        :precision="0"
                        style="width: 227px"
                        @change="value => (formData.barcodeBatchNo[1] = value)"
                    />
                </a-form-item>
            </a-form-item>

            <a-form-item
                label="关键标识"
                name="identifierInfoList"
                :rules="[{ required: true, message: '请先输入标识规则，然后点击右侧+以添加' }]"
            >
                <a-form-item>
                    <a-input-group compact>
                        <a-input-number
                            v-model:value="fromIndex"
                            :min="1"
                            :step="1"
                            :precision="0"
                            style="width: 120px"
                            placeholder="起始位置"
                        />
                        <a-input-number
                            v-model:value="toIndex"
                            :min="1"
                            :step="1"
                            :precision="0"
                            style="width: 120px"
                            placeholder="结束位置"
                        />
                        <a-input
                            v-model:value="identifierValue"
                            showCount
                            placeholder="标识值"
                            style="width: 250px"
                        />
                        <plus-circle-outlined class="add-btn" @click="addIdentifiers" />
                    </a-input-group>
                    <a-input-group>
                        <div
                            v-for="(item, index) in formData.identifierInfoList"
                            :key="index"
                            class="identifier-item"
                        >
                            <div class="start">
                                {{ item.fromIndex }}
                            </div>
                            <div class="end">
                                {{ item.toIndex }}
                            </div>
                            <div class="value">
                                {{ item.identifierValue }}
                            </div>
                            <minus-circle-outlined
                                class="minus-btn"
                                @click="minusIdentifiers(index)"
                            />
                        </div>
                    </a-input-group>
                </a-form-item>
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 18, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiUpdateSupplierBarcodeRule } from "@/api";
import DeviceCategory from "@/components/CategoryModal";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons-vue";
import ProductList from "@/components/ProductList";
import SelectInput from "@/components/SelectInput";
export default defineComponent({
    props: ["upDateRow"],
    emits: ["eventUpdateOk"],
    components: {
        DeviceCategory,
        PlusCircleOutlined,
        MinusCircleOutlined,
        ProductList,
        SelectInput,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            formRef: null,
            productListVisible: false,
            identifierValue: "",
            fromIndex: "",
            toIndex: "",
            formData: {
                id: "",
                name: "",
                code: "",
                item: "",
                itemName: "",
                itemCode: "",
                supplierName: "",
                supplierCode: "",
                barcodeLength: "",
                identifierInfoList: [],
                barcodeBatchNo: [],
                barcodeBatchNoFrom: "",
                barcodeBatchNoTo: "",
            },
        });

        const handleCancel = () => {
            state.visible = false;
            state.formRef.resetFields();
            state.identifierValue = "";
            state.fromIndex = "";
            state.toIndex = "";
            emit("update:upDateRow", null);
        };

        const onSelectProduct = ([{ name, code }]) => {
            state.formData.item = `${name} ${code}`;
            state.formData.itemName = name;
            state.formData.itemCode = code;
        };

        const onFinish = async () => {
            const { formData } = state;
            const { barcodeLength, barcodeBatchNoFrom, barcodeBatchNoTo, identifierInfoList } =
                formData;
            const reqData = {
                ...formData,
                ruleValue: {
                    barcodeLength,
                    barcodeBatchNoFrom,
                    barcodeBatchNoTo,
                    identifiers: identifierInfoList.map(
                        ({ identifierValue, fromIndex, toIndex }) => ({
                            identifierValue,
                            fromIndex,
                            toIndex,
                        })
                    ),
                },
            };
            let res = await apiUpdateSupplierBarcodeRule(reqData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const checkAllSpace = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const onSelectDeviceCategory = ([value]) => {
            state.formData.categoryName = value?.name;
            state.formData.categoryCode = value?.code;
        };

        const checkContainerNo = (rule, value) => {
            if ((value[0] != null && value[1] == null) || (value[1] != null && value[0] == null)) {
                return Promise.reject("请输入完整批次号区间");
            }
            if (value[0] > value[1]) {
                return Promise.reject("起始位置不能大于结束位置");
            }
            if (value[1] > state.formData.barcodeLength) {
                return Promise.reject("区间位置不能大于物料条码位数");
            }
            if (value[1] - value[0] > state.formData.barcodeLength) {
                return Promise.reject("区间长度不能大于物料条码位数");
            } else {
                return Promise.resolve();
            }
        };

        const minusIdentifiers = index => {
            state.formData.identifierInfoList.splice(index, 1);
        };

        const isIntersect = (arr1, arr2) => {
            let start = [Math.min(...arr1), Math.min(...arr2)]; //区间的两个最小值
            let end = [Math.max(...arr1), Math.max(...arr2)]; //区间的两个最大值
            return Math.max(...start) <= Math.min(...end); //最大值里的最小值 是否 小于等于 最大值的最小值
        };

        const addIdentifiers = () => {
            const {
                identifierValue,
                fromIndex,
                toIndex,
                formData: { barcodeLength },
            } = state;
            if (!barcodeLength) return showMessage("info", "请先输入物料条码位数");
            if (identifierValue == "") return showMessage("warning", "请输入标识值");
            if (fromIndex == "") return showMessage("warning", "请输入起始位置");
            if (toIndex == "") return showMessage("warning", "请选择结束位置");
            if (fromIndex > toIndex) return showMessage("info", "起始位置不能大于结束位置");
            if (fromIndex > barcodeLength)
                return showMessage("info", "起始位置不能大于物料条码位数");
            if (toIndex > barcodeLength) return showMessage("info", "结束位置不能大于物料条码位数");
            if (identifierValue?.length != toIndex - fromIndex + 1)
                return showMessage("info", "标识值长度应等于区间长度");
            for (let item of state.formData.identifierInfoList) {
                if (isIntersect([fromIndex, toIndex], [item.fromIndex, item.toIndex])) {
                    return showMessage("info", "区间不能重叠");
                }
            }
            state.formData.identifierInfoList.push({ identifierValue, fromIndex, toIndex });
            state.identifierValue = "";
            state.fromIndex = "";
            state.toIndex = "";
        };

        const setDefaultValue = data => {
            const { ruleValue } = data;
            Object.keys(state.formData).forEach(key => {
                state.formData[key] = data ? data?.[key] : "";
                if (key == "item") {
                    state.formData.item = data ? `${data?.itemName} ${data?.itemCode}` : "";
                }
                if (key == "barcodeLength") {
                    state.formData.barcodeLength = ruleValue?.barcodeLength;
                }
                if (key == "barcodeBatchNoFrom") {
                    state.formData.barcodeBatchNoFrom = ruleValue?.barcodeBatchNoFrom;
                }
                if (key == "barcodeBatchNoTo") {
                    state.formData.barcodeBatchNoTo = ruleValue?.barcodeBatchNoTo;
                }
                if (key == "barcodeBatchNoTo") {
                    state.formData.barcodeBatchNo = [
                        ruleValue?.barcodeBatchNoFrom,
                        ruleValue?.barcodeBatchNoTo,
                    ];
                }
                if (key == "identifierInfoList") {
                    state.formData.identifierInfoList = ruleValue?.identifiers || [];
                }
            });
        };

        watch(
            () => props.upDateRow,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    setDefaultValue(JSON.parse(JSON.stringify(newVal)));
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkAllSpace,
            onSelectDeviceCategory,
            checkContainerNo,
            addIdentifiers,
            minusIdentifiers,
            onSelectProduct,
        };
    },
});
</script>

<style lang="less" scoped>
.contant-item {
    display: flex;
    justify-content: space-between;
}
.require-star {
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
}
.add-btn {
    color: rgba(0, 0, 0, 0.85);
    position: absolute;
    right: -20px;
    top: 8px;
    cursor: pointer;
}
.identifier-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f0f0;
    .start,
    .end {
        width: 120px;
    }
    .value {
        width: 220px;
    }
    .minus-btn {
        color: rgba(0, 0, 0, 0.85);
    }
}
</style>
