<template>
    <div class="serach-block">
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="code" placeholder="请输入规则编码" />
            <a-input v-model:value="name" placeholder="请输入规则名称" />
        </a-space>
        <a-space direction="horizontal" :size="8">
            <a-button type="primary" @click="search">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
    emits: ["search"],
    setup(props, { emit }) {
        const state = reactive({
            code: undefined,
            name: undefined,
        });

        const reset = () => {
            state.code = undefined;
            state.name = undefined;
            search();
        };

        const search = () => {
            let { code, name } = state;
            state.code = code?.trim();
            state.name = name?.trim();
            emit("search", state);
        };

        search();

        return {
            ...toRefs(state),
            search,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .serach-space {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ant-input,
        .ant-select {
            width: 160px;
        }
    }
}
</style>
