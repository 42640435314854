<template>
    <a-drawer
        v-model:visible="visible"
        :maskClosable="false"
        title="设备详情"
        :closable="false"
        @close="handleCancel"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions title="" :column="2">
            <a-descriptions-item label="规则编码">
                {{ detailData?.code }}
            </a-descriptions-item>
            <a-descriptions-item label="规则名称">
                {{ detailData?.name }}
            </a-descriptions-item>
            <a-descriptions-item label="存货名称">
                {{ detailData?.itemName }}
            </a-descriptions-item>
            <a-descriptions-item label="存货编码">
                {{ detailData?.itemCode }}
            </a-descriptions-item>
            <a-descriptions-item label="供应商名称">
                {{ detailData?.supplierName }}
            </a-descriptions-item>
            <a-descriptions-item label="物料条码位数">
                {{ detailData?.ruleValue?.barcodeLength }}
            </a-descriptions-item>
            <a-descriptions-item label="批次号">
                {{
                    detailData?.ruleValue?.barcodeBatchNoFrom
                        ? `${detailData?.ruleValue?.barcodeBatchNoFrom} ~ ${detailData?.ruleValue?.barcodeBatchNoTo}`
                        : ""
                }}
            </a-descriptions-item>
            <a-descriptions-item label="关键标识">
                <div>
                    <div v-for="item in detailData?.ruleValue.identifiers">
                        <span>起始位置：{{ item.fromIndex }}</span>
                        <span style="margin: 0 8px">结束位置：{{ item.toIndex }}</span>
                        <span>标识值：{{ item.identifierValue }}</span>
                    </div>
                </div>
            </a-descriptions-item>
        </a-descriptions>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, computed } from "vue";
import { apiSupplierBarcodeRuleGetInfo } from "@/api";
import { fileURL } from "@/utils/config";

export default defineComponent({
    props: ["detailId"],
    emits: ["update:detailId"],
    setup(props, { emit }) {
        const state = reactive({
            loading: false,
            visible: false,
            detailData: null,
            tableData: [],
        });

        const handleCancel = () => {
            emit("update:detailId");
        };

        const getDetailData = async id => {
            let res = await apiSupplierBarcodeRuleGetInfo(id);
            if (res.status === "SUCCESS") {
                state.detailData = res.data;
            }
        };

        watch(
            () => props.detailId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getDetailData(newVal);
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            fileURL,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
</style>
